import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import Back from "../../components/Back";
import { useLocation } from "react-router-dom";
import { getPaymentsByIdExternalUserAndCycle } from "../../features/payments/paymentsSlice";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getStudentByIdExternalUser } from "../../features/students/studentsSlice";
import moment from "moment";
import HorizontalStepper from "../../components/HorizontalStepper";
import Loader from "../../components/Loader";
import Avatar from "@mui/material/Avatar";
import BasicButton from "../../components/BasicButton";
import { generateLetter } from "../../features/pdfsGenerator/pdfsGeneratorSlice";
import BasicAlert from "../../components/BasicAlert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function Detail() {
  let location = useLocation();
  const path = location.pathname;
  const students = useSelector((state) => state.students.data);
  const payments = useSelector((state) => state.payments.data);
  const isLoadingStudents = useSelector((state) => state.students.isLoading);
  const isLoadingPayments = useSelector((state) => state.payments.isLoading);
  const uuid = path.split("/")[3];
  const ciclo = path.split("/")[4];
  const amountArray = students.amounts
    ? students.amounts.find((registro) => registro.cycle === ciclo)
    : 0;
  const amount = amountArray.amount;
  let totalAmount = 0;
  const currentMonth = moment().format("M");
  const cycle = path.split("/")[4];
  const [displayBasicAlert, setDisplayBasicAlert] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPayments = async () => {
      await dispatch(
        getPaymentsByIdExternalUserAndCycle({ idExternalUser: uuid, cycle })
      );
    };

    fetchPayments();
  }, [dispatch, uuid, cycle]);

  useEffect(() => {
    const fetchStudent = async () => {
      await dispatch(getStudentByIdExternalUser(uuid));
    };

    fetchStudent();
  }, [dispatch, uuid]);

  const headersPagos = [
    payments.length
      ? {
          id: 1,
          title1: "Descripción",
          title2: "Fecha",
          title3: "Cantidad",
          title4: "Comprobante",
          title5: "XML",
        }
      : {
          id: 1,
          title1: "",
          title2: "Aún no existe ningún pago",
          title3: "",
          title4: "",
        },
  ];

  let dataPagos = [];

  for (let index = 0; index < payments.length; index++) {
    totalAmount =
      totalAmount +
      (payments[index].amount ? parseFloat(payments[index].amount) : 0);

    dataPagos.push({
      data1: { type: "text", data: payments[index].description },
      data2: { type: "text", data: payments[index].date },
      data3: { type: "text", data: payments[index].amount },
      data4: { type: "url", data: payments[index].voucher },
      data5: { type: "url", data: payments[index].xml },
    });
  }

  const steps =
    currentMonth <= 6
      ? ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"]
      : ["Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  let activeStep = 0;
  const monthlyPayment = amount / 6;

  if (totalAmount >= monthlyPayment * 6) {
    activeStep = 6;
  } else if (totalAmount >= monthlyPayment * 5) {
    activeStep = 5;
  } else if (totalAmount >= monthlyPayment * 4) {
    activeStep = 4;
  } else if (totalAmount >= monthlyPayment * 3) {
    activeStep = 3;
  } else if (totalAmount >= monthlyPayment * 2) {
    activeStep = 2;
  } else if (totalAmount >= monthlyPayment) {
    activeStep = 1;
  } else {
    activeStep = 0;
  }

  const handleGenerate = async () => {
    await dispatch(
      generateLetter({
        idExternalUser: uuid,
        type: "reEnrollmentLetter",
        cycle: ciclo,
      })
    )
      .then((response) => {
        if (response?.payload?.status) {
          setDisplayBasicAlert(false);
          window.open(response.payload.url, "_blank");
        } else {
          setDisplayBasicAlert(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisplayBasicAlert(true);
      });
  };

  return (
    <Box>
      {" "}
      <Loader loading={isLoadingStudents && isLoadingPayments} />
      <Container>
        <NavBar />
        <Box sx={{ mt: -4 }}>
          <Back
            ruta={
              "/" +
              path.split("/")[1] +
              "/" +
              path.split("/")[2] +
              "/" +
              path.split("/")[3]
            }
          />
        </Box>
        <Box>
          <Box sx={{ mb: 3, textAlign: "left" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
              }}
            >
              <Avatar
                alt="Avatar"
                src={students.pathPhoto}
                sx={{
                  width: { md: 200, xs: 150 },
                  height: { md: 200, xs: 150 },
                  mr: { md: "40px" },
                }}
              />

              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 20, md: 36 },
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                  }}
                >
                  {students.name +
                    (students.secondName
                      ? " " + students.secondName + " "
                      : " ") +
                    students.lastName1 +
                    " " +
                    students.lastName2}
                </Typography>
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt: "-10px",
                  }}
                >
                  Semestre: {students.semester}
                </Typography>
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt: "-10px",
                  }}
                >
                  {students?.typeOfScholarship  && students?.typeOfScholarship !== "ninguna"
                    ? "Tipo de beca:" + students.typeOfScholarship
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt: "-10px",
                  }}
                >
                  {students?.typeOfScholarship  && students?.typeOfScholarship !== "ninguna"
                    ? "Porcentaje: " + students.scholarshipPercentage + "%"
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    color: "#264496",
                    fontFamily: "Nunito, sans-serif",
                    fontSize: { xs: 20, md: 24 },
                    mt: "-10px",
                  }}
                >
                  {students?.typeOfScholarship  && students?.typeOfScholarship !== "ninguna"
                    ? "Estado de beca: " + students.scholarshipStatus
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 20, md: 24 },
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mt: "-10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 20, md: 24 },
                      color: "#264496",
                    }}
                  >
                    Su estado actual es:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, md: 24 },
                      color: students.statusPaid === 1 ? "green" : "red",
                      fontWeight: "bold",
                      ml: 1,
                    }}
                  >
                    {students.statusPaid === 1
                      ? " al corriente"
                      : " pendiente pago"}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ my: 2 }}>
              {totalAmount >= monthlyPayment * 6 ? (
                <BasicButton
                  onClick={handleGenerate}
                  size="small"
                  label="Descarga tu carta de reinscripción"
                  style={{ backgroundColor: "#264496",fontWeight:"bold" ,width:{xs:"100%",md:"auto"}}}
                />
              ) : (
                ""
              )}
            </Box>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 20, md: 32 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Historial de pagos
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: 16, md: 16 },
                color: "gray",
                fontFamily: "Nunito, sans-serif",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              última actualización:{" "}
              {payments?.[0]?.created_at
                ? moment(payments[0].created_at)
                    .locale("es")
                    .format("DD-MM-YYYY")
                : "Sin fecha"}
            </Typography>
            <Box sx={{ my: 4 }}>
              <HorizontalStepper activeStep={activeStep} steps={steps} />
            </Box>
          </Box>
          <Table4Rows data={dataPagos} headers={headersPagos} />
        </Box>
        <BasicAlert
          display={displayBasicAlert}
          severity="error"
          label="Ocurrió un error al generar el PDF. Por favor, contacte a soporte técnico."
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDisplayBasicAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        />
      </Container>
    </Box>
  );
}

export default Detail;
