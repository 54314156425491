export const dataStatus = [
  {
    value: 1,
    label: "Activo",
  },
  {
    value: 5,
    label: "Inactivo",
  },
];

export const dataSex = [
  {
    value: "Hombre",
    label: "Hombre",
  },
  {
    value: "Mujer",
    label: "Mujer",
  },
  {
    value: "Otro",
    label: "Otro",
  },
];

export const dataLevelStudy = [
  {
    value: "Pasante",
    label: "Pasante",
  },
  {
    value: "Licenciatura",
    label: "Licenciatura",
  },
  {
    value: "Maestría",
    label: "Maestría",
  },
  {
    value: "Doctorado",
    label: "Doctorado",
  },
];

export const dataAcademy = [
  {
    value: "Ciencias exactas",
    label: "Ciencias exactas",
  },
  {
    value: "Ciencias naturales",
    label: "Ciencias naturales",
  },
  {
    value: "Humanidades",
    label: "Humanidades",
  },
  {
    value: "Idiomas",
    label: "Idiomas",
  },
  {
    value: "Sistemas, administración y finanzas",
    label: "Sistemas, administración y finanzas",
  },
];

export const dataTypeStaffMember = [
  {
    value: "Patronato",
    label: "Patronato",
  },
  {
    value: "Dirección",
    label: "Dirección",
  },
  {
    value: "Subdirección",
    label: "Subdirección",
  },
  {
    value: "Sistemas",
    label: "Sistemas",
  },
  {
    value: "DAE",
    label: "DAE",
  },
  {
    value: "Recepción",
    label: "Recepción",
  },
  {
    value: "Mantenimiento",
    label: "Mantenimiento",
  },
];

export const dataType = [
  {
    value: "Alumno",
    label: "Alumno",
  },
  {
    value: "Docente",
    label: "Docente",
  },
  {
    value: "Planta",
    label: "Planta",
  },
];

export const dataStatusAlumno = [
  {
    value: 1,
    label: "En curso",
  },
  {
    value: 2,
    label: "Graduado",
  },
  {
    value: 3,
    label: "Intercambio",
  },
  {
    value: 4,
    label: "Baja temporal",
  },
  {
    value: 5,
    label: "Baja definitiva",
  },
  {
    value: 6,
    label: "Alumno Potencial",
  },
];

export const dataScholarshipStatus = [
  {
    value: "Ninguna",
    label: "Ninguna",
  },
  {
    value: "Otorgada",
    label: "Otorgada",
  },
  {
    value: "Refrendo",
    label: "Refrendo",
  },
  {
    value: "Condicionada",
    label: "Condicionada",
  },
  {
    value: "Reducción",
    label: "Reducción",
  },
  {
    value: "Eliminación",
    label: "Eliminación",
  },
];

export const dataPercentageScholarship = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 10,
    label: "10%",
  },
  {
    value: 15,
    label: "15%",
  },
  {
    value: 20,
    label: "20%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 30,
    label: "30%",
  },
  {
    value: 35,
    label: "35%",
  },
  {
    value: 40,
    label: "40%",
  },
  {
    value: 45,
    label: "45%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 55,
    label: "55%",
  },
  {
    value: 60,
    label: "60%",
  },
  {
    value: 65,
    label: "65%",
  },
  {
    value: 70,
    label: "70%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 85,
    label: "85%",
  },
  {
    value: 90,
    label: "90%",
  },
  {
    value: 95,
    label: "95%",
  },
  {
    value: 100,
    label: "100%",
  },
];

export const dataTypeOfScholarship = [
  {
    value: "ninguna",
    label: "Ninguna",
  },
  {
    value: "tradicional",
    label: "Beca Tradicional",
  },
  {
    value: "excelencia",
    label: "Beca de excelencia",
  },
  {
    value: "merito",
    label: "Beca al mérito académico",
  },
  {
    value: "maestro",
    label: "Beca por maestro",
  },
  {
    value: "convenio",
    label: "Beca por convenio",
  },
  {
    value: "lealtad",
    label: "Beca de lealtad",
  },
  {
    value: "intercambio",
    label: "Beca por intercambio",
  },
  {
    value: "acuerdo",
    label: "Beca por acuerdo",
  },
  {
    value: "sedena",
    label: "Beca SEDENA",
  },
  {
    value: "marina",
    label: "Beca Marina",
  },
];

export const dataTutorRelationship = [
  {
    value: "Padre",
    label: "Padre",
  },
  {
    value: "Madre",
    label: "Madre",
  },
  {
    value: "Tío/a",
    label: "Tío/a",
  },
  {
    value: "Abuelo/a",
    label: "Abuelo/a",
  },
  {
    value: "Tutor",
    label: "Tutor",
  },
  {
    value: "Otro",
    label: "Otro",
  },
];


export const dataTypeDocument = [
  {
    value: "conductLetter",
    label: "Carta de buena conducta",
  },
  {
    value: "studyLetterWOQ",
    label: "Constancia de estudios sin calificaciones",
  },
  {
    value: "reportScholarship",
    label: "Reporte de estados de becas",
  },
];

export const dataMonths = [
  {
    value: "1",
    label: "Enero",
  },
  {
    value: "2",
    label: "Febrero",
  },
  {
    value: "3",
    label: "Marzo",
  },
  {
    value: "4",
    label: "Abril",
  },
  {
    value: "5",
    label: "Mayo",
  },
  {
    value: "6",
    label: "Junio",
  },
  {
    value: "7",
    label: "Julio",
  },
  {
    value: "8",
    label: "Agosto",
  },
  {
    value: "9",
    label: "Septiembre",
  },
  {
    value: "10",
    label: "Octubre",
  },
  {
    value: "11",
    label: "Noviembre",
  },
  {
    value: "12",
    label: "Diciembre",
  }
];

export const status = [
  {
    value: "1",
    label: "Activos",
  },
  {
    value: "0",
    label: "Inactivos",
  },
  {
    value: "all",
    label: "Todos",
  },
];

export const dataTypeDocumentStudent = [
  {
    value: "reEnrollmentLetter",
    label: "Carta de reinscripción",
  },
  {
    value: "scholarshipLetter",
    label: "Constancia de servicio becario",
  }
];