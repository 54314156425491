import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: {
    name: "",
    url: "",
  },
  isLoading: false,
  error: [
    {
      message: "",
      code: "",
    },
  ],
};

export const generateLetter = createAsyncThunk(
  "pdfsGenerator/generateLetter",
  async ({ idExternalUser, type,semesterStartDate ,cycle}) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: "Bearer " + token };
      const response = await axios.post(
        process.env.REACT_APP_API_CORE_IMC + "/pdf/generateLetter",
        { idExternalUser, type,semesterStartDate,cycle },
        { headers: headers }
      );

      return response.data;
    } catch (error) {
      if (error.message.includes(401)) {
        window.location.href = "/401";
      }
      throw error;
    }
  }
);

export const pdfsGeneratorSlice = createSlice({
  name: "pdfsGenerator",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateLetter.pending, (state) => {
        state.error = [];
        state.isLoading = true;
      })
      .addCase(generateLetter.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = [];
        state.isLoading = false;
      })
      .addCase(generateLetter.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default pdfsGeneratorSlice.reducer;
