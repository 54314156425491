import * as React from "react";
import Button from "@mui/material/Button";

export default function BasicButtons({ label, size,onClick,disabled = false,style="" }) {
  return (
    <Button onClick={()=>onClick()}  size={size} variant="contained" disabled={disabled} sx={style}>
      {label}
    </Button>
  );
}
