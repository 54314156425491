import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Table4Rows from "../../components/Table4Rows";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import NavBar from "../../components/NavBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Back from "../../components/Back";
import {
  getDataUserScholarship,
  filterScholarshipService,
} from "../../features/scholarshipService/scholarshipServiceSlice";
import Loader from "../../components/Loader";
import { useLocation } from "react-router-dom";
import { generateLetter } from "../../features/pdfsGenerator/pdfsGeneratorSlice";
import BasicButton from "../../components/BasicButton";
import BasicAlert from "../../components/BasicAlert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function ServicioBecarioDetail() {
  let location = useLocation();
  const path = location.pathname;
  const uuid = path.split("/")[3];
  const isLoading = useSelector((state) => state.scholarshipService.isLoading);
  const studentsFilter = useSelector(
    (state) => state.scholarshipService.filter
  );

  const dispatch = useDispatch();
  const [displayBasicAlert, setDisplayBasicAlert] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getDataUserScholarship(uuid));
      await dispatch(filterScholarshipService(""));
    };

    fetchData();
  }, [dispatch, uuid]);

  let dataPagos = [];

  const headers = [
    studentsFilter.length
      ? {
          id: 1,
          title1: "Alumno",
          title2: "Semestre",
          title3: "Horas",
          title5: "Status",
        }
      : {
          id: 1,
          title1: "",
          title2: "No hay datos disponibles",
          title3: "",
        },
  ];

  for (let index = 0; index < studentsFilter.length; index++) {
    dataPagos.push({
      data1: {
        colorTableCellFirst:
          studentsFilter[index].status === "Excelente"
            ? "#0288d1"
            : studentsFilter[index].status === "Bueno"
            ? "#2e7d32"
            : studentsFilter[index].status === "Regular"
            ? "#ed6c02"
            : "#d32f2f",
        type: "text",
        data:
          studentsFilter[index].name +
          (studentsFilter[index].secondName !== null
            ? " " + studentsFilter[index].secondName + " "
            : " ") +
          studentsFilter[index].lastName1 +
          " " +
          studentsFilter[index].lastName2,
      },
      data2: {
        type: "text",
        data: studentsFilter[index].semester,
      },
      data3: {
        type: "text",
        data:
          studentsFilter[index].totalHours +
          " de " +
          studentsFilter[index].daysCurrent,
      },
      data4: {
        type: "chip",
        data: studentsFilter[index].status,
        color:
          studentsFilter[index].status === "Excelente"
            ? "info"
            : studentsFilter[index].status === "Bueno"
            ? "success"
            : studentsFilter[index].status === "Regular"
            ? "warning"
            : "error",
      },
    });
  }

  const handleGenerate = async () => {
    await dispatch(
      generateLetter({
        idExternalUser: uuid,
        type: "scholarshipLetter",
      })
    )
      .then((response) => {
        if (response?.payload?.status) {
          setDisplayBasicAlert(false);
          window.open(response.payload.url, "_blank");
        } else {
          setDisplayBasicAlert(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisplayBasicAlert(true);
      });
  };

  return (
    <Box>
      <Loader loading={isLoading} />
      <Container>
        <NavBar />
        <Back ruta={"/alumnos"} />
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 28, md: 36 },
                color: "#264496",
                fontFamily: "Nunito, sans-serif",
              }}
            >
              Sistema Becario
            </Typography>
          </Box>
          <Box sx={{ my: 2,textAlign:"right"}}>
              { studentsFilter[0]?.totalHours+7 >= studentsFilter[0]?.totalDaysInSemester ? (
                <BasicButton
                  onClick={handleGenerate}
                  size="small"
                  label="Descarga tu constancia de servicio becario"
                  style={{ backgroundColor: "#264496",fontWeight:"bold" ,width:{xs:"100%",md:"auto"}}}
                />
              ) : (
                ""
              )}
            </Box>

          <Table4Rows admin={true} data={dataPagos} headers={headers} />
        </Box>
        <BasicAlert
          display={displayBasicAlert}
          severity="error"
          label="Ocurrió un error al generar el PDF. Por favor, contacte a soporte técnico."
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDisplayBasicAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        />
      </Container>
    </Box>
  );
}

export default ServicioBecarioDetail;
